<template>
  <div>
    <v-navigation-drawer app clipped width="480px" v-model="drawer" :style="[mini ? {'left': '56px' } : {'left': '232px' }]">
      <v-card color="" tile flat class="pa-0">

        <div v-if="getRequestsLoadStatus !== 2">
          <v-skeleton-loader
              v-for="n in 10"
              :key="n"
              class="mt-5"
              type="list-item-avatar-three-line"
          ></v-skeleton-loader>
        </div>

        <div v-else>

          <v-card
              v-for="(request, index) in getRequests"
              :key="index"
              @click="loadRequest({id: request.id})"
              :class="{'bglight': getActiveRequest === request.id}"
              hover
              tile
              elevation="0"
              outlined
              ripple
          >

            <div class="pa-5 d-flex">
              <div>
                <v-img class="mr-4 preview-image" max-width="90px" :src="request.user_profile_picture"></v-img>
              </div>

              <div class="pt-3">
                <div class="text--primary body-1">
                  {{ request.user_name }}
                </div>
                <div class="text-11-px text--secondary">
                  {{ request.vendor_name }}
                </div>
                <!--<div class="text&#45;&#45;primary text-uppercase body-1">
                  Check In: Dec 24 - 1 Month
                </div>-->
              </div>

              <div class="ml-auto font-weight-bold">
                {{ request.request_no }}
                <v-icon small :class="{'text--primary': request.status === 'active', 'text--secondary': request.status === 'draft'}">mdi-circle</v-icon>
              </div>

            </div>

          </v-card>

        </div>

      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Listing',
  props: ['mini'],
  data: () => ({
    drawer: true,
  }),
  computed: {
    ...mapGetters(['getRequests', 'getActiveRequest', 'getRequestsLoadStatus']),
  },
  methods: {
    ...mapActions(['loadRequest']),
  },
  watch: {
    drawer(value) {
      this.$emit('drawer', value);
    },
  }
}
</script>
<style scoped>
.preview-image{
  border-radius: 50px;
  height: 60px;
  width: 60px;
}
.text-11-px {
  font-size: 11px;
}
.active-chat {
  background-color: #F5F6F9;
}
</style>